import './styles/main.scss';

import {
  ApproveCodePage,
  AuthPage,
  ChangePasswordPage,
  RecoveryPasswordPage,
  SettingsPage,
} from 'pages';
import { Layout } from 'widgets';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { XSessionIdKey } from 'shared/contants';
import { AuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const [isAuth, setIsAuth] = useState(!!sessionStorage.getItem(XSessionIdKey));

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthContext.Provider
          value={{
            isAuth,
            setIsAuth,
          }}
        >
          <Pages />
        </AuthContext.Provider>
      </BrowserRouter>
      <ToastContainer closeOnClick theme="colored" />
    </QueryClientProvider>
  );
};

const Pages = () => {
  return (
    <Routes>
      <Route path={RoutesPath.AUTH} element={<AuthPage />} />
      <Route
        path={RoutesPath.RECOVERY_PASSWORD}
        element={<RecoveryPasswordPage />}
      />
      <Route path={RoutesPath.APPROVE_CODE} element={<ApproveCodePage />} />
      <Route
        path={RoutesPath.CHANGE_PASSWORD}
        element={<ChangePasswordPage />}
      />
      <Route element={<Layout />}>
        <Route path={RoutesPath.SETTINGS} element={<SettingsPage />} />
      </Route>
      <Route path="*" element={<Navigate replace to={RoutesPath.SETTINGS} />} />
    </Routes>
  );
};
