import './styles.scss';

import { faGear } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { RoutesPath } from 'shared/routes-path';

export const SidebarNav = () => {
  return (
    <nav className="sidebar__nav">
      <ul className="nav">
        <Link className="nav__item item " to={RoutesPath.SETTINGS}>
          <FontAwesomeIcon icon={faGear} className="size-4" />
          <span className="item__title">Настройки</span>
        </Link>
      </ul>
    </nav>
  );
};
