import './styles.scss';
import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { UUID } from 'uuid-generator-ts';
import * as yup from 'yup';

import { Field, Input, Label } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import { AuthApi } from 'shared/api';
import { XSessionIdKey } from 'shared/contants';
import { onAxiosError } from 'shared/lib';
import { RoutesPath } from 'shared/routes-path';
import type { ApproveCode } from 'shared/typings';
import { Button, ButtonBack } from 'shared/ui';

const schema = yup.object({
  code: yup.string().trim().length(6).required(),
});

export const ApproveCodePage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: ApproveCode) => AuthApi.approveCode(data),
    onSuccess: (data) => {
      if (data.success) {
        navigate(RoutesPath.CHANGE_PASSWORD);
      }
    },
    onError: onAxiosError,
  });

  const onSubmit = async (data: ApproveCode) => {
    sessionStorage.setItem(XSessionIdKey, UUID.createUUID());
    signInMutation.mutate(data);
  };

  return (
    <form
      className="auth-form animate__animated animate__fadeIn bg-brand-1"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="auth-form__header form-header">
        <p className="form-header__header">Подтверждение кода</p>
      </div>
      <div className="auth-form__body form-body">
        <Field className="flex w-full flex-col gap-2">
          <Label className="text-sm/6 font-medium text-white">
            Код подтверждения
          </Label>

          <InputMask
            mask="999999"
            {...register('code')}
            placeholder="Введите данные"
            className={cx(
              'flex items-center rounded-lg border border-white/5 bg-white/5 py-1.5 px-3 text-sm/6 text-white h-12 w-full',
              'focus:outline-none hover:border-white/50',
            )}
          >
            {(props: any) => <Input {...props} placeholder="999999" />}
          </InputMask>
        </Field>
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="auth-form__error">
              {message}
            </span>
          ))}
      </div>
      <div className="auth-form__footer form-actions">
        <Button
          className=" grid h-12 w-fit items-center justify-self-center px-0 text-xl text-white"
          text="Подтвердить"
          disabled={signInMutation.isPending}
        />
        <ButtonBack />
      </div>
    </form>
  );
};
